import { Button, Slider } from 'antd'
import React, { useEffect, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'

const StyledLabel = styled.p`
  font-weight: bold;
  margin: 15px 0 5px;
  font-size: 14px;
`

const Wrapper = styled.div`
  margin-top: 2rem;
  text-align: center;
  position: relative;
`

const Overlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 350px;
  pointer-events: none;
`

const HeadOverlay = styled.div`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 170px;
  border-color: #fff;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
`

const BodyOverlay = styled.div``

const SelfieOverlay = (props) => {
  return (
    <Overlay>
      <HeadOverlay />
      <BodyOverlay />
    </Overlay>
  )
}

const SelfieEditor = (props) => {
  const { editor, setCanSubmit } = props
  const [scale, setScale] = useState(1)
  const [image, setImage] = useState(null)

  useEffect(() => {
    if (image) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }, [image])

  return (
    <div>
      <Dropzone
        onDrop={(dropped) => setImage(dropped[0])}
        style={{ width: '300px', height: '300px' }}
        accept={{ 'image/jpeg': [], 'image/png': [] }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <Button>Click to select files</Button>
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>

      {image && (
        <Wrapper>
          <SelfieOverlay />
          <AvatarEditor
            ref={editor}
            image={image}
            width={300}
            height={300}
            border={0}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={scale}
            rotate={0}
          />
          <StyledLabel>Zoom</StyledLabel>
          <Slider
            onChange={setScale}
            value={scale}
            min={1}
            max={3}
            step={0.01}
          />
        </Wrapper>
      )}
    </div>
  )
}

export default SelfieEditor
