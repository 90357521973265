import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Radio, Space, Tooltip, Upload, message } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import SelfieEditor from './selfie-editor'

const SELFIE_OPTION = {
  DEFAULT: 'default',
  UPLOAD_NEW: 'upload'
}

const SelfieModal = (props) => {
  const { canBypassSelfie, uploadSelfiePhoto, scheduledOffer, onBypassSelfie } =
    props

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selfieSelection, setSelfieSelection] = useState(SELFIE_OPTION.DEFAULT)
  const [canSubmit, setCanSubmit] = useState(true)
  const [loading, setLoading] = useState(false)

  const editor = useRef(null)

  useEffect(() => {
    if (selfieSelection === SELFIE_OPTION.UPLOAD_NEW) {
      setCanSubmit(false)
    } else if (selfieSelection === SELFIE_OPTION.DEFAULT) {
      setCanSubmit(true)
    }
  }, [selfieSelection])

  const handleOpen = () => setIsModalOpen(true)
  const handleClose = () => setIsModalOpen(false)

  const handleChange = (e) => setSelfieSelection(e.target.value)

  const handleBypassSelfie = async () => {
    if (editor) {
      const dataUrl = editor.current.getImage().toDataURL()
      const res = await fetch(dataUrl)
      const blob = await res.blob()

      const formData = new FormData()
      formData.append('selfie', blob, 'selfie.jpg')

      setLoading(true)
      uploadSelfiePhoto(formData)
        .then(() =>
          onBypassSelfie({ offer: scheduledOffer, kind: selfieSelection }).then(
            () => {
              handleClose()
            }
          )
        )
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <>
      <Tooltip
        placement="topRight"
        title="Selfie bypass is available 15 minutes before the starting time."
      >
        <Button
          disabled={!canBypassSelfie}
          loading={loading}
          type="primary"
          onClick={handleOpen}
        >
          Bypass Selfie
        </Button>
      </Tooltip>
      <Modal
        title="Bypass Selfie"
        open={isModalOpen}
        okText="Confirm"
        onOk={handleClose}
        onCancel={handleClose}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleClose}>
            Cancel
          </Button>,
          <Button
            key="submit"
            disabled={!canSubmit}
            type="primary"
            loading={loading}
            onClick={handleBypassSelfie}
          >
            Submit
          </Button>
        ]}
      >
        <Radio.Group onChange={handleChange} value={selfieSelection}>
          <Space direction="vertical">
            <Radio value={SELFIE_OPTION.DEFAULT}>
              Use default profile photo
            </Radio>
            <Radio value={SELFIE_OPTION.UPLOAD_NEW}>
              Upload new selfie photo
            </Radio>
          </Space>
        </Radio.Group>
        {selfieSelection === SELFIE_OPTION.UPLOAD_NEW && (
          <div style={{ marginTop: '15px' }}>
            <SelfieEditor editor={editor} setCanSubmit={setCanSubmit} />
          </div>
        )}
      </Modal>
    </>
  )
}

export default SelfieModal
