import React, { useEffect, useState } from 'react'
import { Col, Collapse, Divider, Empty, Row, Spin, Switch } from 'antd'
import { connect } from 'react-redux'

import {
  bypassSelfie,
  listScheduledOffers,
  uploadSelfie
} from '../../data/scheduled-offer/actions'
import { selectors } from '../../data/selectors'
import { ENTITY_TYPE } from '../../data/entities/constants'
import ScheduledOfferCard from './scheduled-offer-card'
import { getFeedback } from '../../data/feedback/selectors'
import { isEmpty } from 'lodash'
import withSubscription from '../subscription/with-subscription'
import Message from '../../ui/message'

export const FB_BYPASS_SELFIE = 'fbBypassSelfie'

const ScheduledOffersContainer = (props) => {
  const {
    feedback,
    onBypassSelfie,
    onInit,
    scheduledOffers,
    uploadSelfiePhoto
  } = props

  const [isLoading, setIsLoading] = useState(true)
  const [forceUse, setForceUse] = useState(false)

  useEffect(() => {
    onInit().finally(() => setIsLoading(false))
  }, [])

  const scheduledOfferList = scheduledOffers.map((offer) => (
    <ScheduledOfferCard
      feedback={feedback}
      key={offer.id}
      onBypassSelfie={onBypassSelfie}
      scheduledOffer={offer}
      uploadSelfiePhoto={uploadSelfiePhoto}
      forceUse={forceUse}
    />
  ))

  if (isLoading) {
    return (
      <div>
        <Spin style={{ marginRight: '15px' }} />
        Loading...
      </div>
    )
  }

  return (
    <Row>
      <Col xs={24} md={14}>
        {/* <Collapse
          style={{ marginBottom: '20px' }}
          items={[
            {
              key: '1',
              label: 'Selfie Bypass is Disabled',
              children: (
                <div>
                  It has come to our attention that using the Selfie Bypass
                  feature is now leading to account deactivations. As a
                  precaution, we have temporarily disabled this feature until we
                  can ensure it is safe to use again. We are investigating
                  potential ways to reinstate the feature, but please note that
                  Amazon has been rolling out numerous security updates
                  recently. 
                  <br />
                  <br />
                  For users who were using this feature without being
                  detected, we apologize for any inconvenience. Our priority is
                  to protect your account from detections and deactivations. It
                  appears that Amazon has increased their security, resulting in
                  a higher rate of detection for submitting selfies outside of
                  the Flex app.
                </div>
              )
            }
          ]}
          ghost
          defaultActiveKey={['1']}
        />
        <p>I don't care</p>
        <Switch onChange={(checked) => setForceUse(checked)} />
        <Divider /> */}
        <h2>Scheduled Offers</h2>
        {isEmpty(scheduledOfferList) ? (
          <Empty description="No Scheduled Offers" />
        ) : (
          <div>{scheduledOfferList}</div>
        )}
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => {
  return {
    feedback: getFeedback(state, FB_BYPASS_SELFIE),
    scheduledOffers: selectors.list(state, ENTITY_TYPE.SCHEDULED_OFFERS)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: () => dispatch(listScheduledOffers()),
    onBypassSelfie: (data) =>
      dispatch(bypassSelfie(data, { feedbackKey: FB_BYPASS_SELFIE })),
    uploadSelfiePhoto: (data) => dispatch(uploadSelfie(data))
  }
}

export default withSubscription(
  connect(mapStateToProps, mapDispatchToProps)(ScheduledOffersContainer)
)
