import React from 'react'
import { Card, Flex } from 'antd'
import moment from 'moment'
import { styled } from 'styled-components'

import { COLORS, media } from '../../constants/styles'
import { maybeRound } from '../dashboard/helpers'
import SelfieModal from './selfie-modal'

const StyledCard = styled(Card)`
  box-shadow: 0 0 1px 1px ${COLORS.ORANGE_FLEX};
  margin: 20px 0;

  .ant-card-head {
    background-color: ${COLORS.ORANGE_LIGHT};
    min-height: 40px;
    * {
      font-weight: bold;
    }
  }

  .ant-card-head-title {
    display: flex;
    justify-content: space-between;
  }
  ${media.smallOnly`
      .ant-card-head-title {
        display: block;
      }
    `}

  .ant-card-body {
    padding: 10px 24px;
  }

  strong {
    font-weight: bold;
  }
`

const ScheduledOfferCard = (props) => {
  const { onBypassSelfie, scheduledOffer, uploadSelfiePhoto, forceUse } = props

  const startTime = moment.unix(scheduledOffer.start_time)
  const endTime = moment.unix(scheduledOffer.end_time)

  const duration = moment.duration(endTime.diff(startTime))

  let formattedDuration = `${Math.floor(duration.asHours())} hr`
  if (duration.minutes() > 0) {
    formattedDuration += ` ${duration.minutes()} min`
  }

  const title = (
    <>
      <div>
        {startTime.format('ddd, MM/DD')}: {startTime.format('h:mm A')} - {endTime.format('h:mm A')}
      </div>
      <div>${maybeRound(scheduledOffer.rate)} / hour</div>
    </>
  )

  const canBypassSelfie = moment().isAfter(startTime.clone().subtract(15, 'minutes'))

  return (
    <StyledCard title={title}>
      <Flex justify='space-between'>
        <div>
          <div>
            <strong>Duration:</strong> {formattedDuration}
          </div>
          <div>
            <strong>Total pay:</strong> ${scheduledOffer.amount}
          </div>
          <div>
            <strong>Location:</strong> {scheduledOffer.station_name}
          </div>
        </div>
        <SelfieModal
          canBypassSelfie={canBypassSelfie}
          onBypassSelfie={onBypassSelfie}
          uploadSelfiePhoto={uploadSelfiePhoto}
          scheduledOffer={scheduledOffer}
        />
      </Flex>
    </StyledCard>
  )
}

export default ScheduledOfferCard
